import React, { Component } from 'react';
import './assets/css/App.css';

class Countdown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            countdown: {
                years: 0,
                months: 0,
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
                miliseconds: 0,
            }
        }
    }

    componentDidMount() {
        this.tick()
        this.timerID = setInterval(
            () => this.tick(),
            1000
        )
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {      
        const eventDate = new Date(this.props.date)
        const actualDate = new Date()

        const diff = eventDate - actualDate

        if(diff) {
            let countdown = {
                years: Math.floor( diff / (1000 * 60 * 60 * 24 * 30 * 12) ),
                months: Math.floor( diff % (1000 * 60 * 60 * 24 * 30 * 12) / (1000 * 60 * 60 * 24 * 30) ),
                days: Math.floor( diff % (1000 * 60 * 60 * 24 * 30) / (1000 * 60 * 60 * 24) ),
                hours: Math.floor( diff % (1000 * 60 * 60 * 24) / (1000 * 60 * 60) ),
                minutes: Math.floor( diff % (1000 * 60 * 60) / (1000 * 60) ),
                seconds: Math.floor( diff % (1000 * 60) / 1000 ),
                miliseconds: diff % 1000
            }

            if(this.props.widthLeadZero) {
                Object.keys(countdown).forEach((key) => {
                    if(key === 'hours' || key === 'minutes' || key === 'seconds') {
                        if(countdown[key] < 10) {
                            countdown[key] = '0' + countdown[key]
                        }
                    }
                })
            }

            this.setState({countdown: countdown})
        }
        
    }

    render() {
        return (
            <div className="countdown">
                <div className="line1">
                    <div>
                        <div className="value">{this.state.countdown.years}</div>
                        <div className="label">lat</div>
                    </div>
                    <div>
                        <div className="value">{this.state.countdown.months}</div>
                        <div className="label">miesięcy</div>
                    </div>
                    <div>
                        <div className="value">{this.state.countdown.days}</div>
                        <div className="label">dni</div>
                    </div>
                
                    <div>
                        <div className="value">{this.state.countdown.hours}</div>
                        <div className="label">godzin</div>
                    </div>
                    <div>
                        <div className="value">{this.state.countdown.minutes}</div>
                        <div className="label">minut</div>
                    </div>
                    <div>
                        <div className="value">{this.state.countdown.seconds}</div>
                        <div className="label">sekund</div>
                    </div>
                </div>
            </div>
        );
    }
}

class App extends Component {
    componentDidMount(){
        document.title = "Do Ślubu"
    }
    
    render() {
        return (
            <div className="App">
                <div className="background"></div>
                <div className="wrapper">
                    <div className="title">
                        <h1>Do ślubu</h1>
                    </div>

                    <Countdown widthLeadZero date="2021-06-26" />
                </div>
                
            </div>
        );
    }
}

export default App;
